import React from 'react';
import {Text, StyleSheet, Font, View} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import {COLOR, TEXT_SIZES} from "@pdf-elements/styles";
import { FONT } from './styles';

export const HEADING_SIZE = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
}

const Heading = ({ data, size = HEADING_SIZE.H2 }) => {
    const [textElement] = data;
    const text = textElement?.value || '';

    const style = styles[size] || styles[HEADING_SIZE.H2];

    return (
        <View style={style}>
            <Text>{text}</Text>
        </View>
    )
};

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
    ]
});

const defaultStyles = {
    // fontFamily: 'Open Sans',
    color: COLOR.titleColor,
    marginTop: 15,
    marginBottom: 15,
    fontSize: TEXT_SIZES.h2,
    fontWeight: 700,
    fontFamily: FONT.FAMILY,
};

const styles = StyleSheet.create({
    h2: {
        ...defaultStyles
    },
    h3: {
        ...defaultStyles,
        marginBottom: 7,
        fontSize: TEXT_SIZES.h3,
    },
    h4: {
        ...defaultStyles,
        marginBottom: 5,
        fontSize: TEXT_SIZES.h4,
    },
    h5: {
        ...defaultStyles,
        marginBottom: 3,
        fontSize: TEXT_SIZES.h5,
    },
});


Heading.propTypes = {
    data: PropTypes.array,
    size: PropTypes.string,
};

Heading.defaultProps = {
    size: HEADING_SIZE.H2,
};

export default Heading;
