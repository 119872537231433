import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import {
    Heading,
    Paragraph,
    Blockquote,
    List,
} from '@components/pdf-elements';

import { useSavedData } from '@hooks/saved-data';
import { _each, _filter, _find } from '../utils/secure-operations';
import sanitizeUrl from '../utils/sanitize-url';
import { FONT } from '../components/pdf-elements/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const CHAPTER_STRING = 'chapter-';
const FILE_EXTENSION = '.md';

const MYRoadmapPdf = ({ data }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const [steps] = useSavedData([]);
    const [isClient, setIsClient] = useState(false);

    const edges = data?.allMarkdownRemark?.edges;

    const isFrench = language === 'fr';
    const languageEdges = _filter(edges, (edge) => {
        const { node } = edge;
        if (!node) {
            return false;
        }

        const absoluteUrl = node?.fileAbsolutePath;

        if (isFrench) {
            return absoluteUrl.indexOf(`${language}/`) > 0;
        }

        return absoluteUrl.indexOf(`${language}/`) === -1;
    });

    let activeQuestions = [];
    if (steps.length) {
        _each(steps, ({ questions }) => {
            const filteredQuestions = _filter(questions, question => question.active);
            activeQuestions = [...activeQuestions, ...filteredQuestions];
        });

        _each(activeQuestions, question => {
            const { content = {} } = question;
            const { chapter, anchor } = content;
            const anchorId = sanitizeUrl(anchor);
            const fileName = `${CHAPTER_STRING}${chapter}-${anchorId}${FILE_EXTENSION}`;

            question.fileContent = _find(languageEdges, ({ node }) => {
                const absoluteUrl = node?.fileAbsolutePath;
                const pathParts = absoluteUrl.split('/');
                const filenamePart = (pathParts && pathParts.length && pathParts[pathParts.length - 1]) || '';

                return fileName === filenamePart;
            })
        });
    }

    let childrenCounter = 0;
    const pdfElements = [];
    _each(activeQuestions, (question) => {
        const node = question?.fileContent?.node;
        const questionTitle = t(question.question);
        const absoluteUrl = node?.fileAbsolutePath;
        const pathParts = absoluteUrl.split('/');
        const lastPart = (pathParts && pathParts.length && pathParts[pathParts.length - 1]) || '';
        const chapter = lastPart.substr(0, 9);
        const rawElements = node?.htmlAst?.children;
        const questionTitleElement = <Text key={childrenCounter++}
                                           style={[styles.title, childrenCounter === 1 ? { marginTop: 0 } : {}]}
                                           wrap={false}>{questionTitle}</Text>;

        pdfElements.push(questionTitleElement);

        _each(rawElements, (element) => {
            const {
                type,
                tagName,
                children,
                properties,
                value = ''
            } = element;

            if (value === '\n') {
                return;
            }

            let component;

            switch (type) {
                case 'text':
                    component = <Text key={childrenCounter++}>{value}</Text>;
                    break;
                case 'element':
                    switch (tagName) {
                        case 'h1':
                        case 'h2':
                        case 'h3':
                        case 'h4':
                        case 'h5':
                        case 'h6':
                            component = <Heading key={childrenCounter++} data={children} size={tagName}/>;
                            break;
                        case 'p':
                            component = <Paragraph key={childrenCounter++} data={children} chapter={chapter}/>;
                            break;
                        case 'blockquote':
                            component = <Blockquote key={childrenCounter++} data={children}/>;
                            break;
                        case 'ul':
                            component = <List key={childrenCounter++} data={children} chapter={chapter}/>;
                            break;
                        case 'span':
                            const hasParagraph = _find(children, child => child.tagName === 'p');
                            const paragraphChildren = hasParagraph?.children;
                            component = <Paragraph key={childrenCounter++} data={paragraphChildren || children}
                                                   className={properties?.className}/>;
                            break;
                        default:
                            return null;
                    }
                    break;
                default:
                    return null;
            }

            pdfElements.push(component);

        });
    });

    useEffect(() => {
        setIsClient(true)
    }, []);

    return (
        <>
            { isClient && (<PDFViewer style={styles.viewer}>
                <Document title={'My Roadmap'} author={'Looking Forward'}>
                    <Page size="A4" style={styles.body}>
                        <View style={styles.section}>
                            {pdfElements}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>)}
        </>
    );
};

const styles = StyleSheet.create({
    viewer: {
        width: '100%',
        height: '100%',
        fontFamily: FONT.FAMILY,
    },
    body: {
        paddingTop: 30,
        paddingBottom: 65,
        paddingHorizontal: 30,
        backgroundColor: '#FFFFFF',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    title: {
        fontSize: 15,
        borderWidth: 1,
        borderColor: '#DE405A',
        backgroundColor: '#FEF7EB',
        padding: 10,
        marginVertical: 35,
        fontWeight: 'bold',
    }
});


export const query = graphql`
query getPDFFiles {
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
    filter: {fileAbsolutePath: {regex: "/content\/pdf/"}, html: {ne: ""}}
    ) {
      edges {
        node {
          fileAbsolutePath
          htmlAst
        }
      }
    }
}
`;

export default MYRoadmapPdf;
