import React from 'react';
import {StyleSheet, Link} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import {TEXT_SIZES} from "@pdf-elements/styles";


const Anchor = ({data, href, chapter}) => {
    const [textElement] = data;
    const text = textElement?.value || '';
    let realHref = href;
    if (typeof window !== 'undefined') {
        const isExternal = href.indexOf('http') === 0;
        if (!isExternal) {
            const newHref = href.replace('./', '');
            const chaptersPrefix = newHref.indexOf('chapter-') === 0 ? 'chapters/' : '';
            const chapterPrefix = newHref.indexOf('#') === 0 ? `chapters/${chapter}` : '';
            if (chapterPrefix) {
                realHref = `${window.location.origin}/${chapterPrefix}${newHref}`;
            } else if (chaptersPrefix) {
                realHref = `${window.location.origin}/${chaptersPrefix}${newHref}`;
            }
        }
    }
    return (
        <Link src={realHref} style={styles.link}>{text}</Link>
    )
};

const styles = StyleSheet.create({
    link: {
        color: '#111997',
        fontSize: TEXT_SIZES.DEFAULT,
        display: 'inline',
        textDecoration: 'underline',
    }
});

Anchor.propTypes = {
    data: PropTypes.array,
    href: PropTypes.string,
};

Anchor.defaultProps = {
    href: ''
};

export default Anchor;
