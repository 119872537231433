export const COLOR = {
    titleColor: '#000000',
};

const H1_FONT_SIZE = 20;
const HEADING_DECREASE_RATIO = 2;
export const TEXT_SIZES = {
    DEFAULT: 12,
    h1: H1_FONT_SIZE,
    h2: H1_FONT_SIZE - HEADING_DECREASE_RATIO,
    h3: H1_FONT_SIZE - (HEADING_DECREASE_RATIO * 2),
    h4: H1_FONT_SIZE - (HEADING_DECREASE_RATIO * 3),
    h5: H1_FONT_SIZE - (HEADING_DECREASE_RATIO * 4),
};

export const FONT = {
    FAMILY: 'Open Sans',
};
