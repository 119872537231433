import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Paragraph from './Paragraph';

const Blockquote = ({ data }) => {
    let elementsCounter = 0;
    return (
        <View style={styles.view} wrap={false}>
            {
                data && data.length && data.map(child => {
                    const { type, tagName, children } = child;

                    switch (type) {
                        case 'element':
                            switch (tagName) {
                                case 'p':
                                    return <Paragraph key={elementsCounter++} data={children} isOnBlockquote={true} />
                                default:
                                    return null;
                            }
                        default:
                            return null;
                    }
                })
            }
        </View>
    )
};

const styles = StyleSheet.create({
    view: {
        marginBottom: 15,
        lineHeight: 1.3,
        border: 2,
        borderColor: 'black',
        padding: 15,
        paddingTop: 12,
        paddingBottom: 0,
        fontWeight: 700,
    },
});

Blockquote.propTypes = {
    data: PropTypes.array,
};

Blockquote.defaultProps = {};

export default Blockquote;
