import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { _map } from '../../utils/secure-operations';
import ListItem from './ListItem';

const List = ({ data, level, chapter }) => {
    let elementsCounter = 0;
    return (
        <View style={[styles.container, { marginLeft: level * 4 }]}>
                {
                    _map(data, child => {
                        const { type, tagName, children } = child;

                        if (type === 'element' && tagName === 'li') {
                            return <ListItem key={elementsCounter++} data={children} level={level} chapter={chapter}/>;
                        }
                        return null;
                    })
                }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        marginVertical: 0,
    },
});

List.propTypes = {
    data: PropTypes.array,
    level: PropTypes.number,
    chapter: PropTypes.string,
};

List.defaultProps = {
    level: 0,
};

export default List;
