import React from 'react';
import { Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import _isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { TEXT_SIZES } from "@pdf-elements/styles";
import Anchor from './Anchor';
import { _find, _map } from '../../utils/secure-operations';
import { FONT } from './styles';

const getQuoteImage = (children, key) => {
    const image = _find(children, children => children.type === 'element' && children.tagName === 'img');
    if (image) {
        const {
            src,
            title,
        } = image.properties;
        return (
            <View key={key} style={styles.blockquoteImageContainer}>
                {src && <Image src={src} style={styles.blockquoteImage}/>}
                <Text style={{ fontSize: 10 }}>{' '}{title}</Text>
            </View>
        );
    }

    return null;
};

const Paragraph = ({ data, isOnBlockquote, chapter, className }) => {
    let elementsCounter = 0;
    const elementStyles = styles[className] || {};

    const children = _map(data, child => {
        const { type, tagName, value = '', properties = {}, children } = child;

        if (value === '\n') {
            return;
        }

        switch (type) {
            case 'text':
                return <Text key={elementsCounter++} style={elementStyles}>{value}</Text>

            case 'element':
                switch (tagName) {
                    case 'a':
                        if (isOnBlockquote) {
                            const span = _find(children, children => children.tagName === 'span');
                            return getQuoteImage(span?.children, elementsCounter++);
                        }
                        return <Anchor key={elementsCounter++} data={children} href={properties?.href}
                                       chapter={chapter}/>;
                    case 'span':
                        if (isOnBlockquote) {
                            return getQuoteImage(children, elementsCounter++);
                        }
                        const firstChild1 = children[0];
                        const {
                            style = ''
                        } = properties || {};

                        const isUnderline = style === 'text-decoration:underline';
                        const stylesClasses = [];
                        if (isUnderline) {
                            stylesClasses.push(styles.textUnderline);
                        }

                        return <Text style={stylesClasses} key={elementsCounter++}>{firstChild1.value}</Text>;
                    case 'strong':
                        const firstChild = children[0];
                        return <Text key={elementsCounter++}
                                     style={styles.textStrong}>{firstChild.value}</Text>
                    case 'img':
                        const src = properties?.src;
                        const parsedSrc = src.replace('../../', './');
                        const image = !_isEmpty(src) && <Image key={elementsCounter++} src={parsedSrc} />;
                        if (!isOnBlockquote) return image;

                        const text = properties?.alt;
                        return (
                            <View key={elementsCounter++} style={{marginVertical: 10}}>
                                {image}
                                <Text style={styles.author}>{text}</Text>
                            </View>
                        );
                    default:
                        return null;
                }
            default:
                return null;
        }
    });

    return (
        <View style={[styles.view, isOnBlockquote ? { marginBottom: 0 } : {}]}>
            { !isOnBlockquote && (<Text>{ children }</Text>)}
            { isOnBlockquote && children }
        </View>
    )
};

const styles = StyleSheet.create({
    view: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        lineHeight: 1.3,
        fontFamily: FONT.FAMILY,
        fontSize: TEXT_SIZES.DEFAULT,
    },
    textStrong: {
        fontWeight: 700,
    },
    textUnderline: {
        textDecoration: 'underline',
    },
    small: {
      fontSize: TEXT_SIZES.DEFAULT - 2
    },
    author: {
        fontSize: TEXT_SIZES.DEFAULT - 2,
    },
    blockquoteImageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 15,
    },
    blockquoteImage: {
        width: 30,
        height: 30,
        marginRight: 10,
        borderRadius: 50,
    },
    link: {
        color: 'blue',
    }

});

Paragraph.propTypes = {
    data: PropTypes.array,
    isOnBlockquote: PropTypes.bool,
    chapter: PropTypes.string,
};

Paragraph.defaultProps = {
    isOnBlockquote: false,
};

export default Paragraph;
