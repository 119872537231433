import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { TEXT_SIZES } from "@pdf-elements/styles";
import { _find, _map } from '@utils/secure-operations';
import { FONT } from './styles';
import { Paragraph, List } from './index';
import Anchor from './Anchor';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        marginVertical: 5,
        marginLeft: 10,
        fontFamily: FONT.FAMILY,
        fontSize: TEXT_SIZES.DEFAULT,
    },
    strong: {
        fontWeight: 700,
    }
});

const ListItem = ({ data, level, chapter }) => {
    let elementsCounter = 0;
    const hasStrong = !!_find(data, child => {
        return child?.tagName === 'strong';
    });
    const hasElements = !!_find(data, child => {
        return child?.type === 'element' && child?.tagName !== 'a';
    });

    const singleLine = hasStrong || !hasElements;

    const children = _map(data, child => {
        const { type, tagName, value = '', children, properties } = child;

        switch (type) {
            case 'element':
                switch (tagName) {
                    case 'strong':
                        const firstChild =children[0];
                        return <Text key={elementsCounter++} style={styles.strong}>{firstChild?.value}</Text>
                    case 'ul':
                        return <List key={elementsCounter++} data={children} level={level + 1} />
                    case 'p':
                        return (
                            <Text key={elementsCounter++} >
                                <Text>{'• '}</Text>
                                <Paragraph data={children} />
                            </Text>
                        );
                    case 'a':
                        return <Anchor key={elementsCounter++} data={children} href={properties?.href} chapter={chapter} />;
                    default:
                        return null;
                }
            case 'text':
            default:
                return <Text key={elementsCounter++}>{value}</Text>
        }
    });

    return (
        <View style={styles.container}>
            {singleLine && <Text>{'• '}{children}</Text>}
            {!singleLine && children}
        </View>
    )
};

ListItem.propTypes = {
    data: PropTypes.array,
    level: PropTypes.number,
    chapter: PropTypes.string,
};

ListItem.defaultProps = {
    level: 0,
};

export default ListItem;
